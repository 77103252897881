import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imagenGo'
})
export class ImagenGoPipe implements PipeTransform {

  transform( img: string, folder: string ): string {
    return `${ environment.goImageApiUrl }/img/${ folder }/${ img }`;
  }
}
