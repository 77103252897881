import { Injectable } from '@angular/core';
const tinycolor = require("tinycolor2");
export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable()
export class ThemingService {
  primaryColorPalette: Color[] = [];
  secondaryColorPalette: Color[] = [];
  tertiaryColorPalette: Color[] = [];
  successColorPalette: Color[] = [];
  warningColorPalette: Color[] = [];
  dangerColorPalette: Color[] = [];
  
  constructor() {}

  setDefaultPalette() {
    this.savePrimaryColor('#005780');
    this.saveSecondaryColor('#49aa48');
    this.saveTertiaryColor('#6f5cff');
    this.saveSuccessColor('#56DC63');
    this.saveWarningColor('#FFC31B');
    this.saveDangerColor('#EF425A');
  }

  setDefaultDarkModePalette() {
    this.savePrimaryColor('#005780');
    this.saveSecondaryColor('#50C9FE');
    this.saveTertiaryColor('#6f5cff');
    this.saveSuccessColor('#56DC63');
    this.saveWarningColor('#FFC31B');
    this.saveDangerColor('#EF425A');
  }

  savePrimaryColor(color: string) {
    this.primaryColorPalette = this.computeColors(color);
    this.setThemeGlobal(this.primaryColorPalette, '--primary-color');
  }
  saveSecondaryColor(color: string) {
    this.secondaryColorPalette = this.computeColors(color);
    this.setThemeGlobal(this.secondaryColorPalette, '--secondary-color');
  }
  saveTertiaryColor(color: string) {
    this.tertiaryColorPalette = this.computeColors(color);
    this.setThemeGlobal(this.tertiaryColorPalette, '--tertiary-color');
  }
  saveSuccessColor(color: string) {
    this.successColorPalette = this.computeColors(color);
    this.setThemeGlobal(this.successColorPalette, '--success-color');
  }
  saveWarningColor(color: string) {
    this.warningColorPalette = this.computeColors(color);
    this.setThemeGlobal(this.warningColorPalette, '--warning-color');
  }
  saveDangerColor(color: string) {
    this.dangerColorPalette = this.computeColors(color);
    this.setThemeGlobal(this.dangerColorPalette, '--danger-color');
  }
  
  setThemeGlobal(colors: Color[], theme: string){ 
      colors.forEach(color => {
          document.documentElement.style.setProperty(
            `${theme}-${color.name}`,
            color.hex
          );
          document.documentElement.style.setProperty(
            `${theme}-contrast-${color.name}`,
            color.darkContrast ? 'rgba(black, 0.87)' : 'white'
          );
        });
  }

  multiply(rgb1: any, rgb2: any) {
    rgb1.b = Math.floor(rgb1.b * rgb2.b / 255);
		rgb1.g = Math.floor(rgb1.g * rgb2.g / 255);
		rgb1.r = Math.floor(rgb1.r * rgb2.r / 255);
		return tinycolor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
  }
  private computeColors(hex: string): Color[] {
    var baseLight = tinycolor('#ffffff');
    var baseDark = this.multiply(tinycolor(hex).toRgb(), tinycolor(hex).toRgb());
    var baseTriad = tinycolor(hex).tetrad();
    return [
      this.getColorObject(hex, 'base'),
      this.getColorObject(tinycolor(hex).toRgb(), 'rgb'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 5), 'contrast'),
      this.getColorObject(tinycolor(tinycolor.mix(baseLight, hex, 70)).toRgb(), 'contrast-rgb'),
      this.getColorObject(tinycolor.mix(baseDark, hex, 85), 'shade'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 85), 'tint'),
    ];
  }
  private getColorObject(value: string, name: string): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    };
  }
}