import { Injectable, EventEmitter, ApplicationRef } from "@angular/core";
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from "src/environments/environment";
import { NavController } from "@ionic/angular";
import { NotificacionesService } from "./notificaciones.service";
import { ComportamientosService } from "./comportamientos.service";

@Injectable({
  providedIn: "root",
})
export class PushService {
  /**
   * ID del celular para enviarles notificaciones PUSH por medio de OneSignal
   */
  public userID: string = null;

  /**
   * Push token, sirve para OneSignal, aún no se para qué :D
   */
  public pushToken: string = null;

  /**
   * Listado de mensajes push en DB
   */
  public mensajesPush: any[] = [];

  /**
   * Listener para emitir cuando llega una notificación nueva a los que
   * estén suscriptos
   */
  public pushListener = new EventEmitter<any>();

  /**
   * {@inheritdoc}
   */
  constructor(
    private notificacionesService: NotificacionesService,
    private navController: NavController,
    private comportamientos: ComportamientosService
  ) {
    this.cargarMensajes();
  }

  /**
   * Configuración inicial de OneSignal
   */
  public configuracion() {
    let _this = this;
    OneSignal.setAppId(environment.appIdOneSignal);
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      _this.abrirNotificacion(jsonData);
    });
    //Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission 
    //    (See step 6) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });
    OneSignal.getDeviceState(function (stateChanges) {
      _this.userID = stateChanges.userId;
      _this.pushToken = stateChanges.pushToken;
    });
  }

  /**
   *
   * @param notificacion OSNotification
   */
  private async manejarNotificacion(notificacion: any) {
    await this.cargarMensajes();

    const payload = notificacion.payload;
    const existePush = this.mensajesPush.find(
      (mensaje) => mensaje.notificationID === payload.notificationID
    );

    if (existePush) {
      return;
    }

    this.mensajesPush.unshift(payload);
    this.pushListener.emit(payload);

    this.comportamientos.setSinLeer(true);

    //this.navController.navigateRoot('/notificaciones');
  }

  /**
   *
   * @param notificacion OSNotification
   */
  private async abrirNotificacion(notificacion: any) {
    await this.cargarMensajes();
    //await this.turnosService.getTurnosAPI();

    console.log(notificacion);

    if (notificacion.payload && notificacion.payload.additionalData) {
      // Notificaciones
      if (
        notificacion.payload.additionalData["tipo"] == "recordatorio_turno" ||
        notificacion.payload.additionalData["tipo"] == "confirmacion_turno" ||
        notificacion.payload.additionalData["tipo"] == "cancelacion_turno"
      ) {
        this.navController.navigateRoot(
          ["/turnos", "ver", notificacion.payload.additionalData["turno_id"]],
          {
            state: { refresh: true },
            animated: true,
          }
        );
      }

      // Perfil
      else if (
        notificacion.payload.additionalData["tipo"] == "estado_verificacion"
      ) {
        /* this.sessionService.actualizarInfo().then(() => {
          this.navController
            .navigateRoot(["/mi-perfil", "info"], {
              state: { refresh: true },
              animated: true,
            })
            .then(() => {
              this.ref.tick();
            });
        }); */
      }

      // Review turno
      else if (notificacion.payload.additionalData["tipo"] == "review_turno") {
        this.navController.navigateRoot(
          ["/turnos", "ver", notificacion.payload.additionalData["turno_id"]],
          {
            state: { refresh: true, review: true },
            animated: true,
          }
        );
      }

      // Entrada de blog
      else if (notificacion.payload.additionalData["tipo"] == "entrada_blog") {
        this.navController.navigateRoot(
          ["/blog", "entrada", notificacion.payload.additionalData["slug"]],
          {
            state: { refresh: true },
            animated: true,
          }
        );
      } else if (
        notificacion.payload.additionalData["tipo"] == "nuevo_mensaje"
      ) {
        /* this.consultasService._getConsultasAPI().then(() => {
          this.navController.navigateRoot(
            ["/consultas", "ver", notificacion.payload.additionalData["id"]],
            {
              state: { refresh: true },
              animated: true,
            }
          );
        }); */
      }
        // Nueva alarma indicaciones
        else if (notificacion.payload.additionalData["tipo"] == "nueva_alarma") {
          this.navController.navigateRoot(
            ["/indicaciones", "ver",notificacion.payload.additionalData["indicacion_id"]],
            {
              state: { refresh: true },
              animated: true,
            }
          );
        }
    } else {
      this.navController.navigateRoot("/notificaciones");
    }
  }

  /**
   *
   */
  async cargarMensajes() {
    this.notificacionesService.consultarAPI();
  }
}
