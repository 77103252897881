import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';

@Pipe({
  name: 'desde'
})
export class DesdePipe implements PipeTransform, OnDestroy {

  private _timer: number;

  constructor (
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone) {}

  transform(fecha: Date): any {

    this._eliminarTimer();

    let _fecha = new Date(fecha);
    let _fecha_actual = new Date();
    let _segundos = Math.round(
      Math.abs(
        (_fecha_actual.getTime() - _fecha.getTime()) / 1000
      )
    );
    let _tiempoActualizacion = (Number.isNaN(_segundos) ?
      1000 :
      this._getSegundos(_segundos)) * 1000;

    this._timer = this.ngZone.runOutsideAngular(
      () => {
        if (typeof window !== 'undefined') {
          return window.setTimeout(
            () => {
              this.ngZone.run(() => this.changeDetector.markForCheck());
            },
            _tiempoActualizacion
          );
        }
        return null;
      }
    );

		let _minutos = Math.round(Math.abs(_segundos / 60));
		let _horas = Math.round(Math.abs(_minutos / 60));
		let _dias = Math.round(Math.abs(_horas / 24));
		let _meses = Math.round(Math.abs(_dias / 30.416));
    let _years = Math.round(Math.abs(_dias / 365));

		if (Number.isNaN(_segundos)) {
			return '';
		} else if (_segundos <= 45) {
			return 'hace unos segundos';
		} else if (_segundos <= 90) {
			return 'hace 1 minuto';
		} else if (_minutos <= 45) {
			return 'hace ' + _minutos + ' minutos';
		} else if (_minutos <= 90) {
			return 'hace 1 hora';
		} else if (_horas <= 22) {
      return 'hace ' + _horas + ' horas';
		} else if (_horas <= 36) {
			return 'hace 1 día';
		} else if (_dias <= 25) {
      return 'hace ' + _dias + ' días';
		} else if (_dias <= 45) {
			return 'hace un mes';
		} else if (_dias <= 345) {
      return 'hace ' + _meses + ' meses';
		} else if (_dias <= 545) {
			return '1 año';
		} else {
      return 'hace ' + _years + ' años';
		}
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
		this._eliminarTimer();
	}

  /**
   * Eliminar timer
   */
  private _eliminarTimer() {
    if (this._timer) {
      window.clearTimeout(this._timer);
      this._timer = null;
    }
  }

  /**
   * Tiempo en el cual actualizar el timer, en segundos.
   *
   * @param segundos
   */
  private _getSegundos(segundos: number) {
    let min = 60;
    let hr = min * 60;
    let dia = hr * 24;

    if (segundos < min) {
      return 2;
    }

    else if (segundos < hr) {
      return 30;
    }

    else if (segundos < dia) {
      return 300;
    }

    else {
      return 3600;
    }
  }

}
