import { NgModule } from '@angular/core';
import { DesdePipe } from './desde.pipe';
import { SanitizarImagenPipe } from './sanitizar-imagen.pipe';
import { SizePipe } from './size.pipe';
import { ImagenPerfilPipe } from './imagenPerfil.pipe';
import { ResolverDireccionPipe } from './resolver-direccion.pipe';
import { DrivePipe } from './drive.pipe';
import { CachePipe } from './cache.pipe';
import { ImagenGoPipe } from './imagen-go.pipe';

@NgModule({
  declarations: [
    ImagenGoPipe,
    DesdePipe,
    SanitizarImagenPipe,
    SizePipe,
    ImagenPerfilPipe,
    ResolverDireccionPipe,
    DrivePipe,
    CachePipe,
  ],
  exports: [
    ImagenGoPipe,
    DesdePipe,
    SanitizarImagenPipe,
    SizePipe,
    ImagenPerfilPipe,
    ResolverDireccionPipe,
    DrivePipe,
    CachePipe
  ]
})
export class PipesModule { }
