import { TurnosService } from 'src/app/services/turnos.service';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { TurnosActions } from './turnos.actions';
import { tap } from 'rxjs/operators';
import { Turno } from 'src/app/interfaces/turno';
import { patch } from '@ngxs/store/operators';

function getProximo(turnos: Turno[]): Turno {
  if(turnos.length > 0) {
    return turnos.find((turno) => !!turno.confirmado);
  }
}
export class TurnosStateModel {
  public proximosTurnos: Turno[];
  public historialTurnos: Turno[];
  public proximoTurno: Turno;
  public proximoTurnoLoaded: boolean;
  public turnosLoaded: boolean;
}
const defaults = {
  proximosTurnos: [],
  historialTurnos: [],
  proximoTurno: null,
  proximoTurnoLoaded: false,
  turnosLoaded: false,
};
@State<TurnosStateModel>({
  name: 'turnos',
  defaults
})
@Injectable()
export class TurnosState {
  constructor(
    private turnosService: TurnosService,
    private store: Store,
  ) { }
  @Action(TurnosActions.GetListadoTurnos)
  getListadoTurnos({ getState, setState }: StateContext<TurnosStateModel>,
    { tipoTurno, page }: TurnosActions.GetListadoTurnos) {
    setState(
      patch({
        turnosLoaded: false,
      })
    );
    return this.turnosService.getTurnosLista(tipoTurno, page)
      .pipe(
        tap(
          (turnosData) => {
            const state = getState();
            switch (tipoTurno) {
              case 'proximos':
                setState(
                  patch({
                    proximosTurnos: turnosData.info.turnos,
                    historialTurnos: null,
                    turnosLoaded: true,
                  })
                );
                break;
              case 'historial':
                setState(
                  patch({
                    historialTurnos: turnosData.info.turnos,
                    proximosTurnos: null,
                    turnosLoaded: true,
                  })
                );
                break;
            }
          },
          (error) => {
            console.error(error.error)
          }
        )
      )
  }
  @Action(TurnosActions.GetProximoTurno)
  getProximoTurno({ setState }: StateContext<TurnosStateModel>) {
    const turnos: Turno[] = this.store.selectSnapshot((state:{ turnos: { proximosTurnos: Turno[]}}) => state.turnos.proximosTurnos) || [];
    const proximoTurno: Turno = getProximo(turnos);
    if(proximoTurno !== undefined) {
      setState(
        patch({
          proximoTurno: proximoTurno,
          proximoTurnoLoaded: true,
        })
      )
    }
    else {
      setState(
        patch({
          proximoTurnoLoaded: true,
        })
      )
    }
  }
  @Action(TurnosActions.CancelarTurno)
  cancelarTurno({ dispatch }: StateContext<TurnosStateModel>,
    { turnoId, comentario }: TurnosActions.CancelarTurno) {
    return this.turnosService.cancelarTurno(turnoId, comentario)
      .pipe(
        tap(
          () => {
            dispatch([
              new TurnosActions.ClearTurnosState(),
              new TurnosActions.GetListadoTurnos(),
            ])
          },
          (error) => {
            console.error(error.error);
          }
        )
      )
  }
  @Action(TurnosActions.ClearTurnosState)
  clearTurnosState({ getState, setState }: StateContext<TurnosStateModel>) {
    const state = getState();
    setState({
      ...state,
      proximosTurnos: [],
      historialTurnos: [],
      proximoTurnoLoaded: false,
      turnosLoaded: false,
    })
  }
}
