import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AnonimoGuard } from "./guards/anonimo.guard";
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "intro",
    pathMatch: "full",
  },
  {
    path: "home",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "intro",
    canLoad: [AnonimoGuard],
    loadChildren: () =>
      import("./paginas/intro/intro.module").then((m) => m.IntroPageModule),
  },
  {
    path: "login",
    canLoad: [AnonimoGuard],
    loadChildren: () =>
      import("./paginas/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "registro",
    canLoad: [AnonimoGuard],
    loadChildren: () =>
      import("./paginas/registro/registro.module").then(
        (m) => m.RegistroPageModule
      ),
  },
  {
    path: "recordar-clave",
    canLoad: [AnonimoGuard],
    loadChildren: () =>
      import("./paginas/recordar-clave/recordar-clave.module").then(
        (m) => m.RecordarClavePageModule
      ),
  },
  {
    path: "validar-codigo",
    canLoad: [AnonimoGuard],
    loadChildren: () =>
      import("./paginas/validar-codigo/validar-codigo.module").then(
        (m) => m.ValidarCodigoPageModule
      ),
  },
  {
    path: "estudios",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/estudios/estudios.module").then(
        (m) => m.EstudiosPageModule
      ),
  },
  {
    path: "turnos",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/turnos/turnos.module").then((m) => m.TurnosPageModule),
  },
  {
    path: "perfil",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/perfil/perfil.module").then((m) => m.PerfilPageModule),
  },
  {
    path: "ayuda",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/ayuda/ayuda.module").then((m) => m.AyudaPageModule),
  },
  {
    path: "cambiar-clave",
    canLoad: [AnonimoGuard],
    loadChildren: () =>
      import("./paginas/cambiar-clave/cambiar-clave.module").then(
        (m) => m.CambiarClavePageModule
      ),
  },
  {
    path: "notificaciones",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/notificaciones/notificaciones.module").then(
        (m) => m.NotificacionesPageModule
      ),
  },
  {
    path: "modificar-dato-usuario",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import(
        "./modales/modificar-dato-usuario/modificar-dato-usuario.module"
      ).then((m) => m.ModificarDatoUsuarioPageModule),
  },
  {
    path: "ver-turno",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./modales/ver-turno/ver-turno.module").then(
        (m) => m.VerTurnoPageModule
      ),
  },
  {
    path: "cancelar-turno",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./modales/cancelar-turno/cancelar-turno.module").then(
        (m) => m.CancelarTurnoPageModule
      ),
  },
  {
    path: "familia",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/familia/familia.module").then(
        (m) => m.FamiliaPageModule
      ),
  },
  {
    path: "mensajes",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/mensajes/mensajes.module").then(
        (m) => m.MensajesPageModule
      ),
  },
  {
    path: "cambiar-usuario",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/cambiar-usuario/cambiar-usuario.module").then(
        (m) => m.CambiarUsuarioPageModule
      ),
  },
  {
    path: "volver-usuario",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/volver-usuario/volver-usuario.module").then(
        (m) => m.VolverUsuarioPageModule
      ),
  },
  {
    path: "nueva-etiqueta",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./modales/nueva-etiqueta/nueva-etiqueta.module").then(
        (m) => m.NuevaEtiquetaPageModule
      ),
  },
  {
    path: "ayuda",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./modales/ayuda/ayuda.module").then((m) => m.AyudaPageModule),
  },
  {
    path: "etiquetas",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./modales/etiquetas/etiquetas.module").then(
        (m) => m.EtiquetasPageModule
      ),
  },
  {
    path: "login-angular",
    //canLoad: [AnonimoGuard],
    loadChildren: () =>
      import("./paginas/login-angular/login-angular.module").then(
        (m) => m.LoginAngularPageModule
      ),
  },
  {
    path: "afiliaciones",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./paginas/afiliaciones/afiliaciones.module").then(
        (m) => m.AfiliacionesPageModule
      ),
  },
  {
    path: 'urgencias',
    canLoad: [AuthGuard],
    loadChildren: () => import('./modales/urgencias/urgencias.module').then( m => m.UrgenciasPageModule)

  },
  {
    path: 'terminos',
    canLoad: [AnonimoGuard],
    loadChildren: () => import('./paginas/terminos/terminos.module').then( m => m.TerminosPageModule)
  },
  {
    path: 'indicaciones',
    canLoad: [AuthGuard],
    loadChildren: () => import('./paginas/indicaciones/indicaciones.module').then( m => m.IndicacionesPageModule)
  },
  {
    path: 'compartir-estudio',
    canLoad: [AuthGuard],
    loadChildren: () => import('./modales/compartir-estudio/compartir-estudio.module').then( m => m.CompartirEstudioPageModule)
  },
  {
    path: 'politica-privacidad',
    canLoad: [AnonimoGuard],
    loadChildren: () => import('./paginas/politica-privacidad/politica-privacidad.module').then( m => m.PoliticaPrivacidadPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
