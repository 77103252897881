import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  ApplicationRef,
  ChangeDetectorRef,
} from "@angular/core";
import { decode } from "blurhash";

@Component({
  selector: "app-blurhash",
  templateUrl: "./blurhash.component.html",
  styleUrls: ["./blurhash.component.scss"],
})
export class BlurhashComponent implements AfterViewInit {
  private blurHashValue: string;

  @Input()
  get blurHash(): string {
    return this.blurHashValue;
  }

  set blurHash(value: string) {
    this.blurHashValue = value;
    this.decodeBlurHash();
  }

  @Input()
  public imageSrc: string;

  @ViewChild("canvas", { static: true })
  private canvas: ElementRef<HTMLCanvasElement>;

  @Input()
  public canvasWidth = 32;

  @Input()
  public canvasHeight = 32;

  public imageLoaded = false;

  constructor(private ref: ApplicationRef) {}

  public ngAfterViewInit(): void {
    this.decodeBlurHash();
    //this.ref.tick();
    /* let estable = this.ref.isStable.subscribe(
      e => {
        if (e) {
          //estable.unsubscribe();
        }
      }
    ); */
  }

  private decodeBlurHash() {
    if (this.canvas && this.blurHash) {
      const context = this.canvas.nativeElement.getContext("2d");
      const imageData = context.createImageData(
        this.canvasWidth,
        this.canvasHeight
      );
      const pixels = decode(this.blurHash, this.canvasWidth, this.canvasHeight);
      imageData.data.set(pixels);
      context.putImageData(imageData, 0, 0);
    }
  }
}
