export namespace EstudiosActions {
  // Ejecuta la tarea para cachear estudios en backend, este método se encuentra dentro de un intervalo que repite el llamado en estudios.page
  export class ExecuteEstudiosCache {
    static readonly type = '[Estudios] Execute Estudios Cache';
    constructor() { }
  }
  // Ejecuta la tarea que permite cachear el último resultado actualizado del detalle de un estudio específico, este método se encuentra dentro de un intervalo
  // que repite el llamado en estudios/ver.page
  export class ExecuteEstudioDetalleCache {
    static readonly type = '[Estudios] Execute Estudio Detalle Cache';
    constructor(public estudioId: string) { }
  }
  // Carga el listado de estudios, por default en la página 1
  export class LoadEstudios {
    static readonly type = '[Estudios] Load Estudios';
    constructor(public page: number) { }
  }
  // Carga el listado de estudios, teniendo en cuenta parámetros de paginación y filtros
  export class UpdateEstudios {
    static readonly type = '[Estudios] Update Estudios';
    constructor(public limit: number, public page: number, public filter: string) { }
  }
  export class LoadCategorias {
    static readonly type = '[Estudios] Load Categorias';
    constructor() { }
  }
  export class LoadEtiquetas {
    static readonly type = '[Estudios] Load Etiquetas';
    constructor() { }
  }
  export class GetEstudio {
    static readonly type = '[Estudios] Get Estudio';
    constructor(public id: string) { }
  }
  export class ChangeFiltro {
    static readonly type = '[Estudios] Change Filtro';
    constructor(public filtroValue: string) { }
  }
  export class FiltroPorFecha {
    static readonly type = '[Estudios] Filtro Por Fecha';
    constructor() { }
  }
  export class FiltroPorCategoria {
    static readonly type = '[Estudios] Filtro Por Categoría';
    constructor() { }
  }
  export class FiltroPorEtiqueta {
    static readonly type = '[Estudios] Filtro Por Etiqueta';
    constructor() { }
  }
  export class CreateEstudio {
    static readonly type = '[Estudios] Create Estudio';
    constructor(public estudio: any, public estudios: any[]) { }
  }
  export class ShareEstudio {
    static readonly type = '[Estudios] Share Estudio';
    constructor(public id: string, public emailDestinatario: string) { }
  }
  export class DeleteEstudio {
    static readonly type = '[Estudios] Delete Estudio';
    constructor(public id: number) { }
  }
  export class ClearEstudiosState {
    static readonly type = '[Estudios] Clear Estudios State';
    constructor() { }
  }
}