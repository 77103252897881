import { ComportamientosService } from './../../services/comportamientos.service';
import { EstudiosEtiquetasService } from './../../services/estudios-etiquetas.service';
import { EstudiosCategoriasService } from './../../services/estudios-categorias.service';
import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { EstudiosEtiqueta } from 'src/app/interfaces/estudios';
import { EstudiosService } from 'src/app/services/estudios.service';
import { EstudiosActions } from './estudios.actions';
import { CategoriaTag } from 'src/app/interfaces/tag';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { patch } from '@ngxs/store/operators';

export class Paginacion {
  public current: number | null;
  public next: boolean | null;
  public previous: boolean | null;
  public total: number | null;
  public subTotal: number | null;
}
export interface SolicitanteEstudio {
  id_origen: string,
  nombre: string,
  matricula: string,
  matricula_provincia: string
}

export interface ProfesionalEstudio {
  id_origen: string,
  nombre: string,
  matricula: string,
  matricula_provincia: string
}

export interface OsEstudio {
  id_origen: string,
  id_gored: string,
  nombre: string
}

export interface ImagenEstudio {
  id_estudio: string,
  nro_acceso: string,
  descripcion: string
}

export interface AdicionalesEstudio {
  empresa: string,
  equipo: string,
  grupo: string,
  tipo_ingreso: string,
  lugar_ingreso: string
}
export interface Estudio {
  tipo: string,
  origen: number,
  origen_etiqueta: string,
  fecha: string,
  nro_ingreso: string,
  nro_informe: string,
  descripcion: string,
  solicitante: SolicitanteEstudio,
  profesional: ProfesionalEstudio,
  obra_social: OsEstudio,
  pdf: string,
  imagenes: ImagenEstudio[],
  adicionales: AdicionalesEstudio,
  id: string,
}
export class EstudiosStateModel {
  public estudios: Estudio[];
  public categorias: CategoriaTag[];
  public etiquetas: EstudiosEtiqueta[];
  public estudiosFiltrados: Estudio[];
  public estudioSeleccionado: Estudio;
  public cacheStatus: string;
  public paginacion: Paginacion
}
const defaults = {
  estudios: [],
  categorias: [],
  etiquetas: [],
  estudiosFiltrados: [],
  estudioSeleccionado: null,
  cacheStatus: '',
  paginacion: {
    current: null,
    next: null,
    previous: null,
    total: null,
    subTotal: null,
  },
};
@State<EstudiosStateModel>({
  name: 'estudios',
  defaults
})
@Injectable()
export class EstudiosState {
  constructor(
    private estudiosService: EstudiosService,
    private categoriasService: EstudiosCategoriasService,
    private etiquetasService: EstudiosEtiquetasService,
    private comportamientos: ComportamientosService,
  ) { }
  @Action(EstudiosActions.LoadEstudios)
  loadEstudios({ getState, setState }: StateContext<EstudiosStateModel>, { page }: EstudiosActions.LoadEstudios) {
    return this.estudiosService.getListadoEstudios(page)
      .pipe(
        tap(
          (estudiosData) => {

            const paginacion = {
              current: estudiosData.pagination.paginaActual,
              next: estudiosData.pagination.tieneSiguiente,
              previous: estudiosData.pagination.tieneAnterior,
              total: estudiosData.pagination.totalResultados,
              subTotal: estudiosData.pagination.resultadosPorPagina,
            };
            const state = getState();
            setState({
              ...state,
              estudios: estudiosData.estudios,
              paginacion: paginacion
            })
          },
          (error) => {
            console.error(error.error);
          }
        )
      )
  }
  @Action(EstudiosActions.UpdateEstudios)
  updateEstudios({ dispatch }: StateContext<EstudiosStateModel>, { limit, page, filter }: EstudiosActions.UpdateEstudios) {
    dispatch(new EstudiosActions.LoadEstudios(page));
  }
  @Action(EstudiosActions.ExecuteEstudiosCache)
  executeCache({ setState }: StateContext<EstudiosStateModel>) {
    return this.estudiosService.executeEstudiosCache()
      .pipe(
        tap(
          (success) => {
            setState(
              patch({
                cacheStatus: success.tarea.estado
              })
            )
          },
          (error) => { 
            this.comportamientos.mostrarToast('Ocurrió un error al actualizar sus estudios, intente nuevamente.','danger')
           }
        )
      )
  }
  @Action(EstudiosActions.ExecuteEstudioDetalleCache)
  executeDetalleCache({ setState }: StateContext<EstudiosStateModel>, { estudioId }: EstudiosActions.ExecuteEstudioDetalleCache) {
    return this.estudiosService.executeEstudioDetalleCache(estudioId)
    .pipe(
      tap(
        (estudioData) => {
          setState(
            patch({
              estudioSeleccionado: estudioData.actualizacion.estudio
            })
          )
        },
        () => {
          this.comportamientos.mostrarToast('Ocurrió un error al actualizar su estudio, intente nuevamente.','danger')
        }
      )
    )
  }
  @Action(EstudiosActions.LoadCategorias)
  loadCategorias(
    { getState, setState }: StateContext<EstudiosStateModel>
  ) {
    return this.categoriasService.getCategoriasListado()
      .pipe(
        tap(
          (categoriasData) => {
            const state = getState();
            setState({
              ...state,
              categorias: categoriasData.info.categorias
            })
          }
        )
      )
  }
  @Action(EstudiosActions.LoadEtiquetas)
  loadEtiquetas(
    { getState, setState }: StateContext<EstudiosStateModel>
  ) {
    return this.etiquetasService.getEtiquetasListado()
      .pipe(
        tap(
          (etiquetasData) => {
            const state = getState();
            setState({
              ...state,
              etiquetas: etiquetasData.info.etiquetas
            })
          }
        )
      )
  }
  @Action(EstudiosActions.GetEstudio)
  getEstudio({ getState, setState }: StateContext<EstudiosStateModel>,
    { id }: EstudiosActions.GetEstudio) {
      return this.estudiosService.getEstudioSeleccionado(id)
      .pipe(
        tap((estudiosData) => {
          const state = getState();
          setState({
            ...state,
            estudioSeleccionado: estudiosData.estudio,
          })
        },
        () => {

        })
      );
  }
  @Action(EstudiosActions.CreateEstudio)
  createEstudio(
    { dispatch }: StateContext<EstudiosStateModel>,
    { estudio, estudios }: EstudiosActions.CreateEstudio
  ) {
    let formData = new FormData();
    Object.entries(estudio.controls).forEach((campo: any) => {
      if (!campo[1].value) {
        return;
      }
      if (campo[0] == "fecha") {
        let fecha = campo[1].value;
        if (!fecha) {
          fecha = new Date();
        }
        formData.append("fecha", moment(fecha).format("YYYY-MM-DD hh:mm:ss"));
        return;
      }
      if (campo[0] == "etiquetas") {
        (campo[1].value as []).forEach((etiqueta) => {
          formData.append("etiquetas[]", etiqueta);
        });
        return;
      }
      formData.append(campo[0], campo[1].value);
    });
    estudios.forEach((estudio) => {
      formData.append("archivos[]", estudio.blob, estudio.nombre);
    });
    return this.estudiosService.crearEstudioNew(formData)
      .pipe(
        tap(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              const porcentaje = Math.round((100 * event.loaded) / event.total);
              if (porcentaje < 99) {
                this.comportamientos.setMensaje(`${porcentaje}%`);
              } else {
                this.comportamientos.setMensaje("Procesando");
              }
            } else if (event instanceof HttpResponse) {
              this.comportamientos.setMensaje("Cargando");
              if (event.body["estado"]) {
                dispatch(new EstudiosActions.LoadEstudios(1));
              }
            }
          })
      )
  }
  @Action(EstudiosActions.ChangeFiltro)
  changeFiltro(
    { dispatch }: StateContext<EstudiosStateModel>,
    { filtroValue }: EstudiosActions.ChangeFiltro) {
    switch (filtroValue) {
      case "fecha":
        dispatch(new EstudiosActions.FiltroPorFecha());
        break;
      case "categoria":
        dispatch(new EstudiosActions.FiltroPorCategoria());
        break;
      case "etiqueta":
        dispatch(new EstudiosActions.FiltroPorEtiqueta());
        break;
    }
  }
  @Action(EstudiosActions.FiltroPorFecha)
  filtroPorFecha(
    { getState, setState }: StateContext<EstudiosStateModel>) {
    const state = getState();
    let filtrados;
    filtrados = state.estudios.reduce(function (r, a) {
      let fecha = moment(a.fecha).format("DD/MM/yyyy");
      r[fecha] = r[fecha] || [];
      r[fecha].push(a);
      return r;
    }, Object.create(null));
    setState({
      ...state,
      estudiosFiltrados: filtrados
    });
  }
  @Action(EstudiosActions.FiltroPorCategoria)
  filtroPorCategoria(
    { getState, setState }: StateContext<EstudiosStateModel>) {
    // const state = getState();
    // let filtrados;
    // filtrados = state.estudios.reduce(function (r, a) {
    //   let categoria = "Sin categoría";
    //   if (a.estudios_categorias?.length) {
    //     categoria = a.estudios_categorias[0].nombre;
    //   }
    //   r[categoria] = r[categoria] || [];
    //   r[categoria].push(a);
    //   return r;
    // }, Object.create(null));
    // setState({
    //   ...state,
    //   estudiosFiltrados: filtrados
    // });
  }
  @Action(EstudiosActions.FiltroPorEtiqueta)
  filtroPorEtiqueta(
    { getState, setState }: StateContext<EstudiosStateModel>) {
    // const state = getState();
    // let filtrados;
    // filtrados = state.estudios.reduce(function (r, a) {
    //   let etiqueta = "Sin etiqueta";
    //   if (a.estudios_etiquetas?.length) {
    //     etiqueta = a.estudios_etiquetas[0].nombre;
    //   }
    //   r[etiqueta] = r[etiqueta] || [];
    //   r[etiqueta].push(a);
    //   return r;
    // }, Object.create(null));
    // setState({
    //   ...state,
    //   estudiosFiltrados: filtrados
    // });
  }
  @Action(EstudiosActions.ShareEstudio)
  shareEstudio(
    { }: StateContext<EstudiosStateModel>,
    { id, emailDestinatario }: EstudiosActions.ShareEstudio
  ) {
    return this.estudiosService.compartirEstudio(id, emailDestinatario)
      .pipe(
        tap(
          () => {
            this.comportamientos.mostrarToast("El estudio se compartió con éxito");
          },
          (e) => {
            this.comportamientos.mostrarToast(e.error.mensaje, "danger");
            console.error(e)
          }
        )
      )
  }
  @Action(EstudiosActions.DeleteEstudio)
  deleteEstudio(
    { dispatch }: StateContext<EstudiosStateModel>,
    { id }: EstudiosActions.DeleteEstudio
  ) {
    return this.estudiosService.deleteEstudio(id)
      .pipe(
        tap(
          () => {
            dispatch(new EstudiosActions.LoadEstudios(1));
          },
          (e) => { console.error(e); }
        )
      )
  }
  @Action(EstudiosActions.ClearEstudiosState)
  ClearEstudiosState(
    { getState, setState }: StateContext<EstudiosStateModel>
  ) {
    const state = getState();
    setState({
      ...state,
      estudios: [],
      estudioSeleccionado: null,
      estudiosFiltrados: [],
      categorias: [],
      etiquetas: [],
    })
  }
}
