import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { ComportamientosService } from "../services/comportamientos.service";
import { SessionService } from "../services/session.service";

@Injectable({
  providedIn: "root",
})
export class AnonimoGuard implements CanActivate, CanLoad {
  constructor(
    private sessionService: SessionService,
    private comportamientos: ComportamientosService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.sessionService.estaLogueado().then((estaLogueado) => {
      if (estaLogueado) {
        this.comportamientos.navegar(null, ["/home"]);
        return false;
      }

      return true;
    });
  }
}
