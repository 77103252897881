import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { RespuestaServer } from "../interfaces/server";
import { TipoTurno, Turno, Turnos } from "../interfaces/turno";
import { Unidad } from "../interfaces/unidad";
import { ComportamientosService } from "./comportamientos.service";
@Injectable({
  providedIn: "root",
})
export class TurnosService {
  observable: BehaviorSubject<Turnos>;
  observableUnidad: BehaviorSubject<Unidad[]>;
  turnos: Turnos = {
    proximos: [],
    historial: [],
  };
  tipo: TipoTurno = "proximos";
  unidades: Unidad[] = [];
  constructor(
    private http: HttpClient,
    private storage: Storage,
    private comportamientos: ComportamientosService
  ) {
    this.observable = new BehaviorSubject(null);
    this.observableUnidad = new BehaviorSubject([]);
  }
  /**
   * @param turnos
   */
  actualizar(turnos?: Turno[], tipo?: TipoTurno): void {
    if (tipo) {
      this.tipo = tipo;
    }
    if (turnos) {
      this.turnos[this.tipo] = turnos;
    }
    this.observable.next(this.turnos);
  }
  async get(turno_id: number): Promise<Turno> {
    let turno: Turno = null;
    await this.buscarTurnoApi(turno_id);
    const index_proximos = await this.turnos.proximos.findIndex(
      (turno) => turno.id == turno_id
    );
    const index_historial = await this.turnos.historial.findIndex(
      (turno) => turno.id == turno_id
    );
    if (index_historial > -1) {
      return this.turnos.historial[index_historial];
    } else if (index_proximos > -1) {
      return this.turnos.proximos[index_proximos];
    }
  }
  /**
   * @param id_turno
   */
  async buscarTurnoApi(id_turno: string | number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .get<RespuestaServer>(`${environment.apiUrl}/turnos/get/${id_turno}`)
        .subscribe(
          (respuesta) => {
            if (respuesta.estado) {
              this.procesarTurnos([respuesta.info.turno]);
            }
            resolve(respuesta.estado);
          },
          (resp: HttpErrorResponse) => {
            this.comportamientos.mostrarToast(resp.error.mensaje, "danger");
            console.log(resp);
            resolve(false);
          }
        );
    });
  }
  async consultarAPI(
    tipo: TipoTurno = "proximos",
    pagina: string = "1"
  ): Promise<boolean> {
    let params = {
      tipo,
      pagina,
    };
    if (pagina == "1") {
      this.borrar();
    }
    return new Promise<boolean>((resolve) => {
      this.http
        .get<RespuestaServer>(`${environment.apiUrl}/turnos/gored`, { params })
        .subscribe(
          (respuesta) => {
            if (respuesta.estado) {
              this.procesarTurnos(respuesta.info.turnos);
            }
            resolve(respuesta.estado);
          },
          (resp: HttpErrorResponse) => {
            this.comportamientos.mostrarToast(resp.error.mensaje, "danger");
            console.log(resp);
            resolve(false);
          }
        );
    });
  }
  traerUnidades(): Observable<any> {
    let tipo: string = "guardias-videoconsultas";
    let params = {
      tipo
    }
    return this.http.get<any>(`${environment.apiUrl}/unidades`, { params });
  }
  cancelarTurno(id_turno: string | number, comentario: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/turnos/cancelarGo/${id_turno}`, { comentario })
  }
  /**
   * @param turnos
   */
  private procesarTurnos(turnos: Turno[]): void {
    if (this.turnos) {
      this.turnos[this.tipo].push(...turnos);
    }
    this.storage.set("turnos", this.turnos);
    this.actualizar();
  }
  borrar(): void {
    this.turnos = {
      proximos: [],
      historial: [],
    };
    this.actualizar();
  }
  getTurnosLista(tipo: TipoTurno = "proximos", pagina: string = "1"): Observable<any> {
    let params = {
      tipo,
      pagina,
    };
    return this.http.get<any>(`${environment.apiUrl}/turnos/gored`, { params });
  }
}
