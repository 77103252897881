import { Component, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";

@Component({
  selector: "app-menu-lateral",
  templateUrl: "./menu-lateral.component.html",
  styleUrls: ["./menu-lateral.component.scss"],
})
export class MenuLateralComponent implements OnInit {
  esHijo: boolean = false;

  constructor(public platform: Platform) {}

  ngOnInit() {}

  alertaSesion() {}
}
