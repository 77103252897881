import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { Notificacion } from "../interfaces/notificaciones";
import { RespuestaServer } from "../interfaces/server";
import { ComportamientosService } from "./comportamientos.service";

@Injectable({
  providedIn: "root",
})
export class NotificacionesService {
  public observable: BehaviorSubject<Notificacion[]>;
  private notificaciones: Notificacion[] = [];
  private fechaUltimaNotificacion: Date;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private comportamientos: ComportamientosService
  ) {
    this.observable = new BehaviorSubject([]);
    this.consultarAPI();
  }

  actualizar(notificaciones?: Notificacion[]): void {
    if (notificaciones) {
      this.notificaciones = notificaciones;
    }

    this.observable.next(this.notificaciones);
  }

  async consultarAPI(pagina: number = 1, desde?: any): Promise<boolean> {
    let parametros = {};
    parametros["page"] = pagina;

    if (pagina === 1) {
      this.borrar();
    }

    return new Promise<boolean>((resolve) => {
      this.http
        .get<RespuestaServer>(`${environment.apiUrl}/notificaciones`, {
          params: parametros,
        })
        .subscribe(
          (respuesta) => {
            this.notificaciones.push(...respuesta.info.avisos);
            if (this.notificaciones.length) {
              this.fechaUltimaNotificacion = this.notificaciones[0].fecha;
            }
            
            if (!respuesta.pagination.tieneSiguiente) {
              resolve(false);
            }

            this.storage.set("notificaciones", this.notificaciones);
            this.actualizar();

            resolve(respuesta.estado);
          },
          (resp: HttpErrorResponse) => {
            this.comportamientos.mostrarToast(resp.error.mensaje, "danger");
            console.log(resp);
            resolve(false);
          }
        );
    });
  }

  /**
   *
   */
  public borrar() {
    //this.notificaciones = [];
    this.fechaUltimaNotificacion = null;
    this.actualizar([]);
  }
}
