import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Afiliacion } from "../interfaces/afiliacion";
import { BehaviorSubject, Observable } from "rxjs";
import { Storage } from "@ionic/storage";
import { RespuestaServer } from "../interfaces/server";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AfiliacionesService {
  /**
   * Observable de afiliaciones
   */
  private afiliacionesObservable: BehaviorSubject<Afiliacion[]>;

  /**
   * Listado de afiliaciones, el cual se va a ir rellenando entre información
   * de la DB y la API
   */
  private afiliaciones: Afiliacion[] = [];

  /**
   * Fecha del ultimo registro de afiliacion
   * Las busquedas, posteriores a la primera, se realizan con un filtro de fecha
   */
  private fecha_actualizacion: Date;

  /**
   * Observable de afiliacion por defecto
   */
  private afiliacionPorDefectoObservable: BehaviorSubject<Afiliacion>;

  /**
   * Afiliacion por defecto
   */
  private afiliacionPorDefecto: Afiliacion = null;

  constructor(
    private http: HttpClient,
    private storage: Storage,
  ) {
    this.afiliacionesObservable = new BehaviorSubject(null);
    this.afiliacionPorDefectoObservable = new BehaviorSubject(null);

    /**
     * Primero leer si tiene registros en la DB
     * Por mas que tenga o no, consultar a la API
     */
    this.storage.get("afiliaciones").then((afiliaciones) => {
      if (afiliaciones) {
        this.afiliaciones = afiliaciones;
      }

      this.actualizarAfiliacionesMemoria();
      this.actualizarPorDefecto();
      this.getAfiliacionesAPI();
    });
  }

  /**
   * Obtiene el observable de las afiliaciones cargadas en memoria
   */
  public getAfiliacionesMemoria(): Observable<Afiliacion[]> {
    return this.afiliacionesObservable.asObservable();
  }

  /**
   * Informa a todas las suscripciones que hubo cambios en afiliaciones
   */
  public actualizarAfiliacionesMemoria(): void {
    this.afiliacionesObservable.next(this.afiliaciones);
  }

  /**
   * Obtiene el observable de las afiliaciones cargadas en memoria
   */
  public getAfiliacionPorDefecto(): Observable<Afiliacion> {
    return this.afiliacionPorDefectoObservable.asObservable();
  }

  /**
   * Informa a todas las suscripciones que hubo cambios en la afiliacion por defecto
   */
  public actualizarPorDefecto(): void {
    if (this.afiliaciones.length) {
      const afiliacion = this.afiliaciones.find(
        (afiliacion_memoria) => afiliacion_memoria.por_defecto === true
      );
      if (afiliacion) {
        this.afiliacionPorDefecto = afiliacion;
      } else {
        this.afiliacionPorDefecto = null;
      }
    }

    this.afiliacionPorDefectoObservable.next(this.afiliacionPorDefecto);
  }

  /**
   * Obtiene las afiliaciones desde la API
   */
  public async getAfiliacionesAPI(): Promise<boolean> {
    let params = {};

    if (this.fecha_actualizacion) {
      params["desde"] = this.fecha_actualizacion;
    }
    return new Promise<boolean>((resolve) => {
      this.http
        .get<RespuestaServer>(`${environment.apiUrl}/afiliaciones`, {
          params,
        })
        .subscribe((respuesta) => {
          if (respuesta.estado) {
            this.afiliaciones = respuesta.info.afiliaciones;
            this.storage.set("afiliaciones", this.afiliaciones);
          }

          this.actualizarAfiliacionesMemoria();
          this.actualizarPorDefecto();
          resolve(respuesta.estado);
        });
    });
  }

  /**
   * Eliminar afiliacion, tanto en la API cono en DB y memoria
   *
   * @param id ID de afiliacion
   */
  public async eliminarAfiliacion(id: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .delete<RespuestaServer>(
          `${environment.apiUrl}/afiliaciones/eliminar/${id}`
        )
        .subscribe((respuesta) => {
          if (respuesta.estado) {
            this.afiliaciones = respuesta.info.afiliaciones;
            this.storage.set("afiliaciones", this.afiliaciones);
          }

          this.actualizarAfiliacionesMemoria();
          this.actualizarPorDefecto();

          resolve(respuesta.estado);
        });
    });
  }

  /**
   * Devuelve una afiliacion especifica
   *
   * @param id ID de afiliacion
   */
  public async getAfiliacion(id: number): Promise<Afiliacion> {
    await this.getAfiliacionesAPI();
    return new Promise<Afiliacion>((resolve) => {
      let afiliacion = this.afiliaciones.findIndex(
        (afiliacion_memoria) => afiliacion_memoria.id === id
      );
      resolve(null);
    });
  }

  /**
   * Nueva afiliación
   *
   * @param plan_id ID del plan
   * @param numero_afiliado Numero de afiliado
   * @param por_defecto Si es o no la afiliación por defecto
   */
  public async agregarAfiliacion(nueva_afiliacion: {
    plan_id: number;
    numero_afiliado: string;
    por_defecto: boolean;
  }): Promise<RespuestaServer> {
    return new Promise<RespuestaServer>((resolve) => {
      this.http
        .post<RespuestaServer>(
          `${environment.apiUrl}/afiliaciones/agregar`,
          nueva_afiliacion
        )
        .subscribe((respuesta) => {
          if (respuesta.estado) {
            this.afiliaciones = respuesta.info.afiliaciones;
            this.storage.set("afiliaciones", this.afiliaciones);
          }

          this.actualizarAfiliacionesMemoria();
          this.actualizarPorDefecto();

          resolve(respuesta);
        });
    });
  }

  /**
   * Validar afiliación
   *
   * @param afiliacion Afiliacion a validar
   */
  public async validarAfiliacion(
    afiliacion: Afiliacion
  ): Promise<RespuestaServer> {
    return new Promise<RespuestaServer>((resolve) => {
      this.http
        .post<RespuestaServer>(`${environment.apiUrl}/afiliaciones/validar`, {
          afiliacion_id: afiliacion.id,
        })
        .subscribe((respuesta) => {
          if (respuesta.estado) {
            this.afiliaciones = respuesta.info.afiliaciones;
            this.storage.set("afiliaciones", this.afiliaciones);
          }

          this.actualizarAfiliacionesMemoria();
          this.actualizarPorDefecto();

          resolve(respuesta);
        });
    });
  }

  /**
   * Validar afiliación
   *
   * @param afiliacion Afiliacion a validar
   */
  public async setAfiliacionPorDefecto(
    afiliacion: Afiliacion
  ): Promise<RespuestaServer> {
    return new Promise<RespuestaServer>((resolve) => {
      this.http
        .post<RespuestaServer>(
          `${environment.apiUrl}/afiliaciones/setPorDefecto`,
          {
            afiliacion_id: afiliacion.id,
          }
        )
        .subscribe((respuesta) => {
          if (respuesta.estado) {
            this.afiliaciones = respuesta.info.afiliaciones;
            this.storage.set("afiliaciones", this.afiliaciones);
          }

          this.actualizarAfiliacionesMemoria();
          this.actualizarPorDefecto();

          resolve(respuesta);
        });
    });
  }

  /**
   * Borrar del listado de memoria y avisar a todos los subscribers del cambio
   */
  public borrar(): void {
    this.storage.remove("afiliaciones");
    this.afiliaciones = [];
    this.afiliacionPorDefecto = null;
    this.actualizarAfiliacionesMemoria();
    this.actualizarPorDefecto();
  }
}
