import { Pipe, PipeTransform } from '@angular/core';
import { Turno } from '../interfaces/turno';

@Pipe({
  name: 'resolverDireccion'
})
export class ResolverDireccionPipe implements PipeTransform {

  transform(turno: Turno): string {
    let direccion = '';

    if (turno.direccione.direccion_completa) {
      direccion = turno.direccione.direccion_completa;
    } else {
      direccion = `${turno.direccione.calle} ${turno.direccione.numero}, ${turno.direccione.ciudad}, ${turno.direccione.provincia}, ${turno.direccione.pais}`;
    }

    return direccion;
  }

}
