import { Injectable } from "@angular/core";
import { NavController, Platform, ToastController } from "@ionic/angular";
import * as moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Afiliacion } from "../interfaces/afiliacion";
import { Color } from "../interfaces/color";
import { Usuario } from "../interfaces/usuario";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

type DireccionRuta = "back" | "forward";

@Injectable({
  providedIn: "root",
})
export class ComportamientosService {
  private toast: HTMLIonToastElement;

  private spinnerActivoObservable: BehaviorSubject<boolean>;
  sinLeer: boolean = false;

  private sinLeerObservable: BehaviorSubject<boolean>;
  spinnerActivo: boolean[] = [];
  private mensajeObservable: BehaviorSubject<string>;
  private mensaje: string = "Cargando";

  constructor(
    public toastController: ToastController,
    private navController: NavController,
    private platform: Platform,
    public iab: InAppBrowser,
  ) {
    this.spinnerActivoObservable = new BehaviorSubject(false);
    this.sinLeerObservable = new BehaviorSubject(false);
    this.mensajeObservable = new BehaviorSubject(this.mensaje);
  }

  getEstadoSpinner(): Observable<boolean> {
    return this.spinnerActivoObservable.asObservable();
  }
  setEstadoSpinner(estado: boolean): void {
    if (estado) {
      this.spinnerActivo.push(estado);
    } else {
      const index = this.spinnerActivo.findIndex(
        (spinner) => spinner === !estado
      );
      this.spinnerActivo.splice(index, 1);
    }
    const exist = this.spinnerActivo.find((spinner) => spinner === true);
    if (exist) {
      this.spinnerActivoObservable.next(true);
    } else {
      this.spinnerActivoObservable.next(false);
    }
  }

  getSinLeer(): Observable<boolean> {
    return this.sinLeerObservable.asObservable();
  }

  setSinLeer(estado: boolean): void {
    this.sinLeer = estado;
    this.sinLeerObservable.next(estado);
  }

  async mostrarToast(message: string, color: Color = "success"): Promise<any> {
    if (this.toast) {
      this.toast.dismiss();
    }
    this.toast = await this.toastController.create({
      message: message,
      color: color,
      duration: 5000,
    });

    this.toast.present();
  }

  getMensaje(): Observable<string> {
    return this.mensajeObservable.asObservable();
  }

  setMensaje(mensaje: string): void {
    this.mensaje = mensaje;
    this.mensajeObservable.next(this.mensaje);
  }

  esLocal(): boolean {
    return (
      this.platform.url().startsWith("http://192.168.0") ||
      this.platform.url().startsWith("http://localhost:8100") ||
      this.platform.url().startsWith("http://localhost:8200")
    );
  }

  esDesarrollo(): boolean {
    return environment.production;
  }

  navegar(evento: CustomEvent, ruta: any, direccion?: DireccionRuta): void {
    if (direccion == "back") {
      this.navController.navigateBack(ruta, { replaceUrl: true });
    } else {
      this.navController.navigateForward(ruta, { replaceUrl: true });
    }
  }

  busquedaWeb(usuario?: Usuario, afiliacion?: Afiliacion): void {
    const url = new URL(environment.urlGoRed + "/pacientes/cookie");
    if (usuario) {
      url.searchParams.append("email", usuario.email);
      url.searchParams.append("nroDocumento", usuario.numero_documento);
      const fecha = moment(usuario.fecha_nacimiento);
      const day = fecha.format("DD");
      const month = fecha.format("MM");
      const year = fecha.format("yyyy");
      url.searchParams.append("diaFechaNacimiento", day);
      url.searchParams.append("mesFechaNacimiento", month);
      url.searchParams.append("anioFechaNacimiento", year);
    }
    if (afiliacion) {
      url.searchParams.append("plan", afiliacion.plane.id.toString());
      url.searchParams.append(
        "nroAfiliado",
        afiliacion.numero_afiliado.toString()
      );
    }
    const browser = this.iab.create(url.toString(), " ", { location: "yes", fullscreen: "no" });
    browser.show();
  }

  busquedaGuardia(urlGuardia: any, usuario?: Usuario, afiliacion?: Afiliacion): void {

    const url = new URL(environment.urlGoRed + "/pacientes/cookie");
    
    url.searchParams.append("redirect",urlGuardia);
    if (usuario) {
      url.searchParams.append("nombre", usuario.nombre);
      url.searchParams.append("apellido", usuario.apellido);
      url.searchParams.append("email", usuario.email);
      url.searchParams.append("nroDocumento", usuario.numero_documento);
      const fecha = moment(usuario.fecha_nacimiento);
      const day = fecha.format("DD");
      const month = fecha.format("MM");
      const year = fecha.format("yyyy");
      url.searchParams.append("diaFechaNacimiento", day);
      url.searchParams.append("mesFechaNacimiento", month);
      url.searchParams.append("anioFechaNacimiento", year);
    }
    if (afiliacion) {
      url.searchParams.append("plan", afiliacion.plane.id.toString());
      url.searchParams.append(
        "nroAfiliado",
        afiliacion.numero_afiliado.toString()
      );
    }
    const browser = this.iab.create(url.toString(), " ", { location: "yes", fullscreen: "no" });
    browser.show();
  }

  /**Chequea la plataforma en la cual se está utilizando la app y abre la store correspondiente */
  rateApp() {
    if(this.platform.is('desktop')) {
      const url = new URL("https://play.google.com/store/apps/details?id=com.kozaca.portalpacientesgo&hl=en&gl=US");
      window.open(url.toString());
    }
    else if(this.platform.is('android')) {
      const url = new URL("market://details?id=com.kozaca.portalpacientesgo");
      window.open(url.toString());
    }
    else if(this.platform.is('ios')) {
      const url = new URL("https://apps.apple.com/ar/app/go-portal-paciente/id1576748450");
      // const browser: ThemeableBrowserObject = this.themeableBrowser.create(url.toString(), '_blank', options);
    }
  }
}
