import { HttpCancelService } from './services/http-cancel.service';
import { LOCALE_ID, NgModule } from "@angular/core";
import localeEsAr from "@angular/common/locales/es-AR";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { MenuLateralModule } from "./componentes/menu-lateral/menu-lateral.module";
import { BlurhashModule } from "./componentes/blurhash/blurhash.module";
import { SpinnerComponent } from "./componentes/spinner/spinner.component";
import { IonicStorageModule } from "@ionic/storage";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { SessionService } from "./services/session.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { jwtOptionsFactory } from "./jwt.factory";
import { HttpConfigInterceptor } from "./interceptors/http-config.interceptor";
import { registerLocaleData } from "@angular/common";
import { StarRatingModule } from "ionic5-star-rating";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { Camera } from "@ionic-native/camera/ngx";
import { Chooser } from "@ionic-native/chooser/ngx";
import { FileChooser } from "@ionic-native/file-chooser/ngx";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";
import { PipesModule } from "./pipes/pipes.module";
import { FilePath } from "@ionic-native/file-path/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { QRCodeModule } from "angularx-qrcode";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { File } from "@ionic-native/file/ngx";
import { HttpErrorInterceptor } from "./interceptors/error.interceptor";
import { ComportamientosService } from "./services/comportamientos.service";
import { ThemingService } from './services/theming.service';
import { NgxsModule } from '@ngxs/store';
import { environment } from "src/environments/environment";
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ManageHttpInterceptor } from './interceptors/managehttp.interceptor';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { EstudiosState } from './store/estudios/estudios.state';
import { TurnosState } from './store/turnos/turnos.state';

registerLocaleData(localeEsAr, "es-Ar");

@NgModule({
    declarations: [AppComponent, SpinnerComponent],
    imports: [
        BrowserModule,
        MenuLateralModule,
        BlurhashModule,
        IonicModule.forRoot({
            rippleEffect: true,
            mode: "ios",
        }),
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        StarRatingModule,
        PipesModule,
        QRCodeModule,
        IonicStorageModule.forRoot({
            name: "__pp_gored",
            driverOrder: ["localstorage", "indexeddb", "sqlite", "websql"],
        }),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [SessionService],
            },
        }),
        NgxsModule.forRoot([EstudiosState, TurnosState], {
            developmentMode: !environment.production
        }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        CookieService,
        AndroidPermissions,
        ScreenOrientation,
        OneSignal,
        Camera,
        Chooser,
        File,
        FileChooser,
        FilePath,
        PhotoViewer,
        InAppBrowser,
        ThemingService,
        HttpCancelService,
        FileTransfer,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
            deps: [ComportamientosService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ManageHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: "es-Ar",
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
