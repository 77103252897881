import { Injectable } from "@angular/core";
import { Usuario } from "../interfaces/usuario";
import { Storage } from "@ionic/storage";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { RespuestaServer } from "../interfaces/server";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import { ComportamientosService } from "./comportamientos.service";

@Injectable({
  providedIn: "root",
})
export class UsuarioService {
  // public usuarioLogueado: Usuario = null;

  public usuariosAccesibles: Usuario[] = [];
  private usuariosAccesiblesObservable: BehaviorSubject<Usuario[]>;

  private usuarioLogueadoObservable: BehaviorSubject<Usuario>;
  private usuarioLogueado: Usuario;

  private generos = {
    f: "Femenino",
    m: "Masculino",
  };

  respuestaServer: RespuestaServer;

  /**
   * {@inheritdoc}
   */
  constructor(
    private storage: Storage,
    private http: HttpClient,
    private comportamientos: ComportamientosService
    ) {
    this.usuarioLogueadoObservable = new BehaviorSubject(
      null
    ) as BehaviorSubject<Usuario>;

    this.usuariosAccesiblesObservable = new BehaviorSubject([]);

    this.getUsuario().then((usuario) => {
      this.setUsuarioLogueado(usuario);
      this.usuariosAccesibles.push(usuario);
    });
  }

  /**
   *
   */
  public getUsuariosAccesibles(): Observable<Usuario[]> {
    return this.usuariosAccesiblesObservable.asObservable();
  }

  /**
   *
   */
  public getUsuarioLogueado(): Observable<Usuario> {
    return this.usuarioLogueadoObservable.asObservable();
  }

  /**
   *
   * @param valor
   */
  public setUsuarioLogueado(valor): void {
    this.usuarioLogueado = valor;
    this.usuarioLogueadoObservable.next(this.usuarioLogueado);
  }

  /**
   *
   * @param campo
   */
  async getUsuario(campo?): Promise<Usuario> {
    let usuario = await this.storage.get("usuario");
    this.usuarioLogueado = usuario;

    if (campo) {
      return usuario[campo];
    }

    return usuario;
  }

  /**
   *
   * @param usuario
   */
  setUsuario(usuario: Usuario) {
    this.storage.set("usuario", usuario);
    this.usuarioLogueado = usuario;
    this.setUsuarioLogueado(usuario);
    this.usuariosAccesibles.push(usuario);
  }

  /**
   *
   * @param campo
   */
  async setCampo(
    campo: string,
    valor: string | number | Date
  ): Promise<RespuestaServer> {
    return new Promise<RespuestaServer>((resolve) => {
      this.http
        .post<RespuestaServer>(`${environment.apiUrl}/usuarios/update-campos`, {
          campo,
          valor,
        })
        .subscribe((respuestaTurno) => {
          this.actualizarInfo();
          resolve(respuestaTurno);
        },
        
        (resp: HttpErrorResponse) => {
          this.comportamientos.mostrarToast(resp.error.mensaje, "danger");
          console.log(resp);     
        },
        );
    });
  }

  /**
   * Recordar clave
   *
   * @param usuario FormGroup
   */
  async recordarClave(usuario: UntypedFormGroup): Promise<RespuestaServer> {
    let datos = {};
    datos['origen'] = environment.origen;
    Object.entries(usuario.controls).forEach((campo) => {
      if (!campo[1].value) {
        return;
      }
      datos[campo[0]] = campo[1].value;
    });

    return new Promise<RespuestaServer>((resolve) => {
      this.http
        .post(`${environment.apiUrl}/usuarios/recuperarClave`, datos)
        .subscribe(
          (respuesta: RespuestaServer) => {
            this.respuestaServer = respuesta;
            resolve(respuesta);
          },
          (error) => console.log(error)
        );
    });
  }

  /**
   * Validar código
   *
   * @param usuario Object
   */
  async validarCodigo(usuario: Object): Promise<RespuestaServer> {
    return new Promise<RespuestaServer>((resolve) => {
      this.http
        .post(`${environment.apiUrl}/usuarios/validarCodigo`, usuario)
        .subscribe(
          (respuesta: RespuestaServer) => {
            this.respuestaServer = respuesta;
            resolve(respuesta);
          },
          (error) => {
            console.log(error);
          }
        );
    });
  }

  /**
   *
   */
  public async nuevaClave(params: object): Promise<RespuestaServer> {
    params['origen'] = environment.origen;
    return new Promise<RespuestaServer>((resolve) => {
      this.http
        .post<RespuestaServer>(
          `${environment.apiUrl}/usuarios/nuevaClave`,
          params
        )
        .subscribe((respuestaTurno) => {
          resolve(respuestaTurno);
        });
    });
  }

  /**
   *
   */
  public async cambiarClave(params: object): Promise<RespuestaServer> {
    params['origen'] = environment.origen;
    return new Promise<RespuestaServer>((resolve) => {
      this.http
        .post<RespuestaServer>(
          `${environment.apiUrl}/usuarios/cambiar-clave`,
          params
        )
        .subscribe((respuestaTurno) => {
          resolve(respuestaTurno);
        });
    });
  }

  /**
   *
   */
  getGeneros() {
    return this.generos;
  }

  /**
   *
   * @param imagen
   */
  public async subirImagenPerfil(imagen): Promise<boolean> {
    const formData = new FormData();
    formData.append("archivo", imagen.blob ? imagen.blob : imagen, imagen.name);
    formData.append("tipo", "perfil");
    return new Promise<boolean>((resolve) => {
      this.http
        .post(`${environment.apiUrl}/imagenes/upload`, formData)
        .subscribe(async (respuesta: RespuestaServer) => {
          if (respuesta.estado) {
            this.setCampo("gimagen", respuesta["info"]["archivo"]["archivo"]);
          }
          resolve(respuesta.estado);
        });
    });
  }

  /**
   *
   */
  public async actualizarInfo(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .get(`${environment.apiUrl}/usuarios/get`)
        .subscribe((respuesta: RespuestaServer) => {
          //this.respuestaServer = respuesta;
          if (respuesta["estado"]) {
            if (respuesta["paciente"][0]["familia"]) {
              respuesta["paciente"][0]["tiene_familiares"] = true;
            }
            this.setUsuario(respuesta["paciente"][0]);
          }

          resolve(respuesta["estado"]);
        });
    });
  }
}
