import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "../../environments/environment";
import { Usuario } from "../interfaces/usuario";

@Pipe({
  name: "imagenPerfil",
})
export class ImagenPerfilPipe implements PipeTransform {
  transform(usuario: Usuario, size?: number): string {
    let string = `${environment.apiUrl}/imagenes/perfil/${usuario.slug}`;
    if (size) {
      string += `/${size}`;
    }
    return string;
  }
}
