// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: "1.2.23",
  apiUrl: "https://api-qa.gored.com.ar",
  goImageApiUrl: "https://demo.gored.com.ar",
  apiKey: "",
  nombreToken: "token",
  nombreRefreshToken: "refresh_token",
  authHeaderKey: "Authorization",
  authPrefix: "Bearer ",
  appIdOneSignal: "0cc71fe1-3d32-45ed-ac9b-62e75219c9d6",
  googleProjectNumber: "305169433622",
  urlRedi: "https://new.red-i.com.ar",
  urlGoRed: "https://gored.com.ar",
  proxyRedi: "https://api-qa.gored.com.ar/r",
  origen: 'go'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
