import { TipoTurno } from './../../interfaces/turno';
export namespace TurnosActions {
  export class GetListadoTurnos {
    static readonly type = '[Turnos] Get Listado Turnos';
    constructor(public tipoTurno: TipoTurno = 'proximos', public page: string = '1') { }
  }
  export class GetProximoTurno {
    static readonly type = '[Turnos] Get Proximo Turno';
    constructor() { }
  }
  export class ClearTurnosState {
    static readonly type = '[Turnos] Clear Turnos State';
    constructor() { }
  }
  export class CancelarTurno {
    static readonly type = '[Turnos] Cancelar Turno';
    constructor(public turnoId: string | number, public comentario: string) { }
  }
}

