import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'drive'
})
export class DrivePipe implements PipeTransform {
  transform( img: string, size?: number ): string {

    let url = `${ environment.urlGoRed }/imagenes/get/${ img }`;

    if (size) {
      url += `/${ size }`;
    }

    return url;
  }
}
