import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EstudiosCategoria } from "../interfaces/estudios";
import { RespuestaServer } from "../interfaces/server";
import { ComportamientosService } from "./comportamientos.service";

@Injectable({
  providedIn: "root",
})
export class EstudiosCategoriasService {
  public observable: BehaviorSubject<EstudiosCategoria[]>;
  private categorias: EstudiosCategoria[] = [];

  constructor(
    private http: HttpClient,
    private comportamientos: ComportamientosService
  ) {
    this.observable = new BehaviorSubject([]);
    this.consultarAPI();
  }

  actualizar(categorias?: EstudiosCategoria[]): void {
    if (categorias) {
      this.categorias = categorias;
    }

    this.observable.next(this.categorias);
  }

  async set(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {});
  }

  async get(id: number): Promise<EstudiosCategoria> {
    let indice = this.getIndex(id);

    if (indice < 0) {
      await this.consultarAPI();
      indice = this.getIndex(id);
    }

    return Promise.resolve(this.categorias[indice]);
  }

  async eliminar(id: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {});
  }

  async consultarAPI(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .get<RespuestaServer>(`${environment.apiUrl}/estudios-categorias`)
        .subscribe(
          (respuesta) => {
            this.actualizar(respuesta.estado ? respuesta.info.categorias : []);
            resolve(respuesta.estado);
          },
          (resp: HttpErrorResponse) => {
            this.comportamientos.mostrarToast(resp.error.mensaje, "danger");
            resolve(false);
          }
        );
    });
  }

  private getIndex(id: Number): number {
    return this.categorias.findIndex((estudio) => estudio.id == id);
  }

  borrar(): void {}
  getCategoriasListado(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/estudios-categorias`);
  }
}
