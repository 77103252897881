import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { BlurhashComponent } from "./blurhash.component";

@NgModule({
  declarations: [BlurhashComponent],
  exports: [BlurhashComponent],
  imports: [CommonModule, IonicModule],
})
export class BlurhashModule {}
