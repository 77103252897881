import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { ComportamientosService } from "../services/comportamientos.service";
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private comportamientos: ComportamientosService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.comportamientos.setEstadoSpinner(true);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.comportamientos.setEstadoSpinner(false);
        return throwError(error);
      }),
      finalize(() => {
        this.comportamientos.setEstadoSpinner(false);
      })
    );
  }
}
