import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EstudiosEtiqueta } from "../interfaces/estudios";
import { RespuestaServer } from "../interfaces/server";
import { ComportamientosService } from "./comportamientos.service";
@Injectable({
  providedIn: "root",
})
export class EstudiosEtiquetasService {
  observable: BehaviorSubject<EstudiosEtiqueta[]>;
  private etiquetas: EstudiosEtiqueta[] = [];
  constructor(
    private http: HttpClient,
    private comportamientos: ComportamientosService
  ) {
    this.observable = new BehaviorSubject([]);
    this.consultarAPI();
  }
  actualizar(etiquetas?: EstudiosEtiqueta[]): void {
    if (etiquetas) {
      this.etiquetas = etiquetas;
    }

    this.observable.next(this.etiquetas);
  }
  async get(id: number): Promise<EstudiosEtiqueta> {
    let indice = this.getIndex(id);
    if (indice < 0) {
      await this.consultarAPI();
      indice = this.getIndex(id);
    }
    return Promise.resolve(this.etiquetas[indice]);
  }
  async consultarAPI(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .get<RespuestaServer>(`${environment.apiUrl}/estudios-etiquetas`)
        .subscribe(
          (respuesta) => {
            this.actualizar(respuesta.estado ? respuesta.info.etiquetas : []);
            resolve(respuesta.estado);
          },
          (resp: HttpErrorResponse) => {
            this.comportamientos.mostrarToast(resp.error.mensaje, "danger");
            resolve(false);
          }
        );
    });
  }
  /**
   * Nueva etiqueta
   *
   * @param etiqueta
   */
  async nueva(etiqueta): Promise<any> {
    return new Promise<any>((resolve) => {
      this.http
        .post<RespuestaServer>(
          `${environment.apiUrl}/estudios-etiquetas/agregar`,
          etiqueta
        )
        .subscribe((respuestaEtiqueta) => {
          if (respuestaEtiqueta.estado) {
            this.etiquetas.push(respuestaEtiqueta.info.etiqueta);
          }

          this.actualizar();

          resolve(respuestaEtiqueta);
        });
    });
  }
  /**
   *
   * @param id ID de la etiqueta
   */
  async eliminar(id: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .delete<RespuestaServer>(
          `${environment.apiUrl}/estudios-etiquetas/eliminar/${id}`
        )
        .subscribe((respuesta) => {
          resolve(respuesta.estado);
          this.consultarAPI();
        });
    });
  }
  /**
   *
   * @param id
   */
  private getIndex(id: Number): number {
    return this.etiquetas.findIndex((estudio) => estudio.id == id);
  }
  /**
   *
   */
  borrar(): void {
    this.actualizar([]);
  }
  getEtiquetasListado(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/estudios-etiquetas`);
  }
}
