import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { EMPTY, from, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { SessionService } from "../services/session.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  /**
   * La idea del interceptor es que a cada consulta
   * que se realice se le agreguen los headers
   * correspondientes.
   *
   * Existen algunos casos donde no se deberían
   * enviar, por ende agrego excepciones
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.endsWith("/token/obtener") ||
      req.url.endsWith("/usuarios/registro_app") ||
      req.url.endsWith("/usuarios/recuperarClave") ||
      req.url.endsWith("/usuarios/validarCodigo") ||
      req.url.endsWith("/usuarios/nuevaClave")
    ) {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      };
      req = req.clone({
        setHeaders: headers,
      });
      return next.handle(req);
    }

    // a todas las otras requests, agregarles el JWT
    return from(this.sessionService.getToken()).pipe(
      switchMap((token) => {
        if (token) {
          let headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          };
          req = req.clone({
            setHeaders: headers,
          });
          return next.handle(req);
        } else {
          return EMPTY;
        }
      })
    );
  }
}
