import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ComportamientosService } from "src/app/services/comportamientos.service";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnInit {
  mensaje: Observable<string> = this.comportamientos.getMensaje();
  spinnerState: Observable<boolean> = this.comportamientos.getEstadoSpinner();
  constructor(public comportamientos: ComportamientosService) {}

  ngOnInit() {
  }
}
