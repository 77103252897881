import OneSignal from 'onesignal-cordova-plugin';
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { RespuestaServer } from "../interfaces/server";
import { UsuarioService } from "./usuario.service";
import { ComportamientosService } from "./comportamientos.service";
import { UntypedFormGroup } from "@angular/forms";

const JWT = new JwtHelperService();
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class SessionService {

  /**Regex para corroborar que nombre y apellido tengan solo letras. */
  regexString = /^[A-zÀ-ź\s\ñ\Ñ\']+$/;

  /** Informa si el usuario actual es hijo */
  private esHijoObservable: BehaviorSubject<boolean>;

  /** Definicion de si es hijo o no */
  private esHijo: boolean = false;

  /** Cache de la ultima respuesta del server */
  respuestaServer: RespuestaServer;

  public emailEnRecuperacion: string = null;

  public codigoDeRecuperacion: string = null;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private platform: Platform,
    private cookiesService: CookieService,
    private usuarioService: UsuarioService,
    private comportamientos: ComportamientosService,
  ) {
    this.esHijoObservable = new BehaviorSubject(false);
  }

  /**
   *
   */
  getEsHijo(): Observable<boolean> {
    return this.esHijoObservable.asObservable();
  }

  async comprobarEsHijo(): Promise<any> {
    const comprobacion = (await this.storage.get("esHijo")) || false;
    this.setEsHijo(comprobacion);
  }

  /**
   *
   * @param valor
   */
  async setEsHijo(valor?: boolean): Promise<any> {
    this.esHijo = valor;
    this.storage.set("esHijo", this.esHijo);
    this.esHijoObservable.next(this.esHijo);
  }

  /**
   *
   */
  async generarTokens(usuario): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http.post(`${environment.apiUrl}/token/obtener`, usuario).subscribe(
        (respuesta) => {
          if (respuesta["estado"]) {
            this.setToken(respuesta["token"]);
            this.setRefreshToken(respuesta["refresh_token"]);
          }

          resolve(respuesta["estado"]);
        },
        (resp: HttpErrorResponse) => {
          this.comportamientos.mostrarToast(resp.error.mensaje, "danger");
          console.log(resp);
          resolve(false);
        }
      );
    });
  }

  /**
   *
   * @param token
   */
  setToken(token: string): void {
    this.storage.set(environment.nombreToken, token);
  }

  /**
   *
   * @param token
   */
  async setTokenTutor(token?: string): Promise<any> {
    if (!token) {
      token = await this.getToken();
    }
    this.setEsHijo(true);
    return this.storage.set("tokenTutor", token);
  }

  /**
   *
   */
  async getToken(): Promise<any> {
    let token = await this.storage.get(environment.nombreToken);
    if (!token) {
      const tokenAngular = window.localStorage.getItem("tokenRedIApi");
      if (tokenAngular) {
        if (JWT.decodeToken(tokenAngular).usuario) {
          this.setToken(tokenAngular);
          token = tokenAngular;
        }
      }
    }

    return token;
  }

  /**
   *
   */
  async getTokenTutor(): Promise<any> {
    this.storage.get("tokenTutor").then((valor) => {
      this.setEsHijo(valor ? true : false);
      return Promise.resolve(valor);
    });
  }

  /**
   *
   */
  async removeTokenTutor(): Promise<any> {
    await this.storage.remove("tokenTutor");
    this.setEsHijo(false);
    return Promise.resolve(true);
  }

  /**
   *
   * @param token
   */
  setRefreshToken(token: string): void {
    this.storage.set(environment.nombreRefreshToken, token);
  }

  /**
   *
   */
  private getRefreshToken(): Promise<string> {
    return this.storage.get(environment.nombreRefreshToken);
  }

  /**
   *
   */
  async login(usuario: { email: string; password: string }): Promise<boolean> {
    // const token = await this.generarTokens(usuario);
    await this.generarTokens(usuario);

    return new Promise<boolean>((resolve) => {
      this.http
        .post(`${environment.apiUrl}/usuarios/loginAppTest`, usuario)
        .subscribe(
          (respuesta: RespuestaServer) => {
            this.respuestaServer = respuesta;
            if (respuesta["estado"]) {
              this.usuarioService.setUsuario(respuesta["paciente"][0]);
              /**Si se accede desde una app se ingresa en este bloque que
               * contiene una función de OneSignal que permite setear un
               * Id Externo de Usuario que sirve para mapear todos los player_id
               * (IDs de dispositivos) para saber a que dispositivos se les debe enviar notificaciones
               */
              if (this.platform.ready() && this.platform.platforms().includes("android")) {
                OneSignal.setExternalUserId(respuesta["paciente"][0]["id"], respuesta["externalUserIdAuthHash"]);
              }
            }
            resolve(this.respuestaServer.estado);
          },
          (error) => {
            resolve(false);
          }
        );
    });
  }

  /**
   * Log out
   */
  async logout(): Promise<void> {
    /**
     * Borrar el local storage de angular, si es que existe
     */
    window.localStorage.removeItem("tokenRedIApi");
    window.localStorage.removeItem("refreshRedIApi");
    window.localStorage.removeItem("refreshExpiration");

    this.setEsHijo(false);

    await this.storage.remove(environment.nombreRefreshToken);
    await this.storage.remove(environment.nombreToken);

    this.cookiesService.deleteAll();

    return this.storage.clear();
  }

  async estaLogueado(): Promise<any> {
    let tokenExpirado = await this.tokenExpirado();

    if (tokenExpirado) {
      let refresh = await this.refreshTokens();

      if (!refresh) {
        return Promise.resolve(false);
      }
    }

    return Promise.resolve(true);
  }
  /**
   *
   */
  public async loginToken(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .post(`${environment.apiUrl}/usuarios/login_token`, {})
        .subscribe((respuesta: RespuestaServer) => {
          this.respuestaServer = respuesta;
          if (respuesta["estado"]) {
            this.usuarioService.setUsuario(respuesta["paciente"][0]);

            if (this.platform.platforms().includes("android")) {
              //this.ga.setUserId(respuesta['paciente'][0]['hash']);
            }

            resolve(true);
          }
          resolve(false);
        });
    });
  }
  /**
   *
   * @param usuario
   */
  async refreshTokens(): Promise<boolean> {
    const refresh_token = await this.getRefreshToken();

    if (!refresh_token) {
      return Promise.resolve(false);
    }

    return new Promise<boolean>((resolve) => {
      this.http.post(`${environment.apiUrl}/token/refrescar`, {}).subscribe(
        (respuesta) => {
          if (respuesta["estado"]) {
            this.setToken(respuesta["token"]);
            this.setRefreshToken(respuesta["refresh_token"]);
            return resolve(true);
          }

          this.logout();
          return resolve(false);
        },
        (error) => {
          this.logout();
          return resolve(false);
        }
      );
    });
  }

  /**
   *
   * @param token
   */
  async tokenExpirado(token?: string): Promise<any> {
    if (!token) token = await this.getToken();
    if (!token) return true;

    return JWT.isTokenExpired(token);
  }

  /**
   *
   */
  async registro(usuario: UntypedFormGroup, appID?: string): Promise<any> {
    let formData = {};
    formData['origen'] = environment.origen;
    Object.entries(usuario.controls).forEach((campo) => {
      if (!campo[1].value) {
        return;
      }

      if (campo[0] == "nombre") {
        if (!this.regexString.test(campo[1].value)) {
          this.comportamientos.mostrarToast('El formato de nombre no está permitido, utilice solo letras.', 'danger');
          return false;
        }
      }

      if (campo[0] == "apellido") {
        if (!this.regexString.test(campo[1].value)) {
          this.comportamientos.mostrarToast('El formato de apellido no está permitido, utilice solo letras.', 'danger');
          return false;
        }
      }

      if (campo[0] == "dni") {
        if ((campo[1].value).toString().length < 6) {
          this.comportamientos.mostrarToast('El numero mínimo de dígitos de DNI no es válido.', 'danger');
          return false;
        }
      }

      if (campo[0] == "fecha_nacimiento") {
        formData["fecha_nacimiento"] = moment(campo[1].value).format(
          "YYYY-MM-DD"
        );
        return;
      }

      formData[campo[0]] = campo[1].value;
    });

    if (appID) {
      formData["appID"] = appID;
    }

    return new Promise<any>((resolve) => {
      this.http
        .post(`${environment.apiUrl}/usuarios/registro_app`, formData)
        .subscribe((respuesta: RespuestaServer) => {
          this.respuestaServer = respuesta;
          if (respuesta.estado) {
            /**Si se accede desde una app se ingresa en este bloque que
              * contiene una función de OneSignal que permite setear un
              * Id Externo de Usuario que sirve para mapear todos los player_id
              * (IDs de dispositivos) para saber a que dispositivos se les debe enviar notificaciones
              */
            if (this.platform.ready() && this.platform.platforms().includes("android")) {
            OneSignal.setExternalUserId(respuesta["paciente"][0]["id"], respuesta["externalUserIdAuthHash"]);
            }
            this.usuarioService.setUsuario(respuesta["paciente"][0]);
            resolve(respuesta["paciente"][0]);
          }
        },
          //Cambiado para que procese el error cuando no devuelve true
          (error: HttpErrorResponse) => {
            this.comportamientos.mostrarToast(error.error.mensaje, "warning");
            resolve(false);
          }
        );
    });
  }
}
