import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Estudio } from "../interfaces/estudios";
import { ComportamientosService } from "./comportamientos.service";
@Injectable({
  providedIn: "root",
})
export class EstudiosService {
  estudiosOrdenados = [];
  public observable: BehaviorSubject<Estudio[]>;
  private estudios: Estudio[] = [];
  constructor(
    private http: HttpClient,
    private comportamientos: ComportamientosService,
  ) {
    this.observable = new BehaviorSubject([]);
  }
  actualizar(estudios?: Estudio[]): void {
    if (estudios) {
      this.estudios = estudios;
    }

    this.observable.next(this.estudios);
  }
  crearEstudioNew(formData): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/estudios/agregar`,
      formData,
      {
        reportProgress: true,
        observe: "events",
      })
  }
  deleteEstudio(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/estudios/eliminar/${id}`)
  }
  getListadoEstudios(page: number = 1): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/estudios-v2/index?page=${page}`);
  }
  executeEstudiosCache(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/estudios-v2/actualizarListado`, {});
  }
  executeEstudioDetalleCache(idEstudio: string): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/estudios-v2/actualizarDetalleEstudio/${idEstudio}`, {});
  }
  getEstudioSeleccionado(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/estudios-v2/detalleEstudio/${id}`)
  }
  compartirEstudio(estudioId: string, emailDestinatario: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/estudios-v2/compartir/${estudioId}`, {
      email: emailDestinatario,
    })
  }
}
