import { Component, OnDestroy } from "@angular/core";
import { AlertController, Platform } from "@ionic/angular";
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { PushService } from "./services/push.service";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { SessionService } from "./services/session.service";
import { ComportamientosService } from "./services/comportamientos.service";
import { AfiliacionesService } from "./services/afiliaciones.service";
import { UsuarioService } from "./services/usuario.service";
import { environment } from "src/environments/environment";
import { ThemingService } from "./services/theming.service";
import { Subscription } from "rxjs";
import { Afiliacion } from "./interfaces/afiliacion";
import { Usuario } from "./interfaces/usuario";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnDestroy {
  /**
   * Booleano para saber si es hijo o tutor
   * En caso de ser hijo, muestra un botón que te deja
   * volver a tu cuenta
   */
  esHijo: boolean = false;

  /**
   * Usuario logueado para mostrar la info en sidebar
   */
  usuario: Usuario;

  /**
   * Afiliación por defecto para mostrar en sidebar
   * Con el cambio de relación entre obras sociales y planes
   * ahora obtenemos el nombre del plan y de la OS desde
   * afiliaciones.
   */
  afiliacion: Afiliacion;

  private _suscripcionUsuario: Subscription;

  /**
   * Listado de redes en el sidebar
   */
  redes = [
    // {
    //   nombre: "Facebook",
    //   icono: "logo-facebook",
    //   link: "https://www.facebook.com/somosredi",
    // },
    // {
    //   nombre: "Instagram",
    //   icono: "logo-instagram",
    //   link: "https://www.instagram.com/somosredi",
    // },
    // {
    //   nombre: "Linkedin",
    //   icono: "logo-linkedin",
    //   link: "https://www.linkedin.com/company/somosredi",
    // },
    /* {
      nombre: 'Youtube',
      icono: 'logo-youtube',
      link: 'https://www.youtube.com/channel/UCutJnM8jX-XMiI8JlvJTJTg'
    } */
  ];

  /**
   * Número de versión de app para mostrar en sidebar
   * Se obtenía nativo desde Android, pero para unificar
   * ahora se obtiene desde environment para todas las
   * plataformas.
   */
  versionApp: string;
  render: boolean = true;
  appPaused: boolean = false;
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private alertController: AlertController,
    private pushService: PushService,
    private screenOrientation: ScreenOrientation,
    private sessionsService: SessionService,
    private usuarioService: UsuarioService,
    private afiliacionesService: AfiliacionesService,
    public comportamientos: ComportamientosService,
    public themingService: ThemingService,
  ) {
    this.initializeApp();
  }
  ngOnDestroy(): void { }


  /**
   * Configuración inicial de la aplicación
   * Este evento se dispara cuando ionic inicia
   * la app.
   */
  initializeApp() {
    SplashScreen.hide();
    /** Comprobar que la plataforma esté lista antes de consultar y modificar */
    this.platform.ready().then(() => {
      /**
       * El statusBar es la barra superior que aparece
       * en los dispositivos móviles. Donde se ven los
       * iconos de las notificaciones, batería, etc.
       */
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString("005780");

      this.versionApp = environment.version;

      /**
       * Seteamos el tema inicial de la app con ThemingService
       */     
        this.themingService.setDefaultPalette();
        
      /**
       * Para manejar el botón de volver atrás en móvil
       * se lo apago. Con el router de Android no se bien
       * como manejar el historial y etc., el tema de volver
       * atrás no lo estaba manejando bien, así que lo manejo
       * individualmente en cada página
       */
      this.platform.backButton.subscribeWithPriority(98, () => {
        return;
      });
      this._suscripcionUsuario =this.usuarioService.getUsuarioLogueado().subscribe((usuario) => {
        //Change detection no funciona en este caso por que el link de la imagen no cambia
        if (JSON.stringify(this.usuario) !== JSON.stringify(usuario)) {
          this.render = false;
          setTimeout(() => {
            this.render = true;
          }, 50);
        }
        this.usuario = usuario;
      });

      this.sessionsService.getEsHijo().subscribe((esHijo) => {
        this.esHijo = esHijo;
      });
      this.sessionsService.comprobarEsHijo();

      this.afiliacionesService
        .getAfiliacionPorDefecto()
        .subscribe((afiliacion) => {
          this.afiliacion = afiliacion;
        });

      if (this.platform.is("android")) {
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.PORTRAIT
        );

        /* if (this.analytics.DEFAULT_EVENTS) {
            this.analytics
              .logEvent(this.analytics.DEFAULT_EVENTS.APP_OPEN)
              .then((r) => console.log(r));
          } */

        /**
         * Todo lo que tenga que ver con los PUSH está
         * en su propio servicio.
         */
        this.pushService.configuracion();
      }
    });
    /**Establece timer de 5 minutos para cerrar sesión cuando la aplicacion se cierra */
    this.platform.pause.subscribe(() => {
      // 300000
      // 1440000
      this.appPaused = true;
      setTimeout(() => {
        this._logout()
      }, 300000);
    });
    /**Establece timer de 4 horas para cerrar sesión cuando la aplicacion se reanuda */
    this.platform.resume.subscribe(() => {
      // 300000
      // 1440000
      this.appPaused = true;
      setTimeout(() => {
        this._logout()
      }, 1440000);
    });
  }

  /**
   * Alerta para cerrar sesión
   */
  public async alertaSesion(): Promise<void> {
    const alerta = await this.alertController.create({
      cssClass: "alerta-chica",
      header: "Confirmar",
      message: "Estás a punto de cerrar sesión, ¿continuar?",
      buttons: [
        {
          text: "No",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            this.alertController.dismiss();
          },
        },
        {
          text: "Si",
          handler: () => {
            this._logout();
          },
        },
      ],
    });

    await alerta.present();
  }

  /**
   * Método para meter todo lo que tenga que ver
   * con cerrar la sesión.
   * Actualmente mucho no hace, pero utilizar
   * este en caso que se requiera agregar algo.
   */
  private async _logout() {
    await this.sessionsService.logout();
    this.comportamientos.navegar(null, ["/login"]);
    location.reload();
  }
}